import './Zipper.scss';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import slide from './slide.png';
import toothBottom from './toothBottom.png';
import toothPair from './toothPair.png';
import toothTop from './toothTop.png';

export class Zipper extends React.Component {

	constructor(props) {
		super(props);

		const {
			zipPercent = 100,
		} = this.props;

		this.state = {
			hidden: true,
			movingLeft: true,
			toothHeight:11,
			toothPairHeight: 18,
			toothPairWidth: 8,
			toothWidth:8,
			trackHeight: 28,
			zipPercent: zipPercent,
		};
		this.toothBottomElementAdded = false;
		this.toothPairElementAdded = false;
		this.toothTopElementAdded = false;

		this.canvasBottomRef = React.createRef();
		this.canvasTopRef = React.createRef();
		this.trackRef = React.createRef();
		this.zipperRef = React.createRef();

		this.drawInCanvases = this.drawInCanvases.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.recordOriginalHeight = this.recordOriginalHeight.bind(this);
	}

	componentDidMount = () => {
		// console.log("componentDidMount");

		// window.addEventListener('load', this.recordOriginalHeight);
		this.recordOriginalHeight();
		if (!this.toothPairElementAdded) {
			const toothPairElement = document.createElement('img');
			toothPairElement.classList.add(`zipper-tooth-pair-element`);
			toothPairElement.classList.add(`hidden`);
			toothPairElement.setAttribute(`src`, toothPair);
			document.getElementById('root').appendChild(toothPairElement);
			this.toothPairElementAdded = true;

			const toothTopElement = document.createElement('img');
			toothTopElement.classList.add(`zipper-tooth-top-element`);
			toothTopElement.classList.add(`hidden`);
			toothTopElement.setAttribute(`src`, toothTop);
			document.getElementById('root').appendChild(toothTopElement);
			this.toothTopElementAdded = true;
			toothTopElement.onload = () => {
				this.setState({toothTopElement:toothTopElement});
			};

			const toothBottomElement = document.createElement('img');
			toothBottomElement.classList.add(`zipper-tooth-bottom-element`);
			toothBottomElement.classList.add(`hidden`);
			toothBottomElement.setAttribute(`src`, toothBottom);
			document.getElementById('root').appendChild(toothBottomElement);
			this.toothBottomElementAdded = true;
			toothBottomElement.onload = () => {
				this.setState({toothBottomElement:toothBottomElement});
			};

			const slideElement = document.createElement('img');
			slideElement.classList.add(`zipper-slide-element`);
			slideElement.classList.add(`hidden`);
			slideElement.setAttribute(`src`, slide);
			document.getElementById('root').appendChild(slideElement);
			this.slideElementAdded = true;
			slideElement.onload = () => {
				this.setState({
					slideWidth: slideElement.offsetWidth
				});
			};
		}
		const style = getComputedStyle(this.zipperRef.current);
		const {
			backgroundColor: backgroundColour,
		} = style;
		this.setState({
			backgroundColour:backgroundColour,
			hidden: false,
		});

		this.handleResize();

	};

	componentDidUpdate = () => {
		this.drawInCanvases();
	};

	componentWillUnmount = () => {
		// window.removeEventListener('load', this.recordOriginalHeight);
	};

	drawInCanvases = () => {
		const {
			backgroundColour,
			removed = false,
			toothBottomElement,
			toothHeight,
			toothPairWidth,
			toothTopElement,
			toothWidth,
			trackHeight,
			unzipped,
			zipPercent,
		} = this.state;

		if (!removed && toothBottomElement && toothTopElement) {
			const _this = this;
			const canvasBottom = this.canvasBottomRef.current;
			const canvasTop = this.canvasTopRef.current;
			const track = this.trackRef.current;
			if (canvasTop && canvasBottom) {
				const ctxBottom = canvasBottom.getContext("2d");
				const ctxTop = canvasTop.getContext("2d");
				const {
					height: canvasHeight,
					width: canvasWidth,
				} = canvasTop;

				const height = canvasHeight;
				const trackHeightDiv2 = trackHeight / 2;
				window.getSelection().removeAllRanges();
				if (unzipped) {
					// Animate receeding top and bottom parts of zip
					let h = canvasHeight;
					track.style.width = `0px`;

					const drawTeeth = () => {
						const { removed = false } = _this.state;
						if (!removed) {
							h -= 5;
							if (h <= trackHeightDiv2) {
								h = trackHeightDiv2;
								_this.setState({ fadeout: true });
								setTimeout(() => {
									_this.setState({ removed: true });
								}, 1000); // Must match fade in css.
								cancelAnimationFrame(this.frameID);
							}
							let xBottom = canvasWidth;
							let xTop = xBottom;
							ctxTop.clearRect(0, 0, canvasWidth, height);
							ctxBottom.clearRect(0, 0, canvasWidth, height);

							canvasTop.setAttribute("height", h);
							canvasBottom.setAttribute("height", h);

							track.style.top = `${h - trackHeightDiv2}px`;

							ctxTop.fillStyle = ctxBottom.fillStyle = backgroundColour;

							while (xBottom > 0) {
								const yTop = (h - trackHeightDiv2) * (Math.sin(Math.PI * 0.5 + (xTop) * Math.PI / (- canvasWidth)) + 1) / 2;
								const yBottom = h - toothHeight - (h - trackHeightDiv2) * (Math.sin(Math.PI * 0.5 + (xTop) * Math.PI / (canvasWidth)) + 1) / 2;

								// Draw vertical column in background colour above/below teeth
								ctxTop.fillRect(xTop, 0, toothWidth, yTop + 5);
								ctxBottom.fillRect(xBottom, yBottom + toothHeight - 5, toothWidth, h - yBottom - toothHeight + 5);
								ctxTop.save();
								ctxBottom.save();

								ctxBottom.shadowColor = '#00000050';
								ctxBottom.shadowBlur = 4;
								ctxBottom.shadowOffsetY = 0;
								ctxBottom.drawImage(toothBottomElement, xBottom, yBottom, toothWidth, toothHeight);

								ctxTop.shadowColor = '#00000070';
								ctxTop.shadowBlur = 4;
								ctxTop.shadowOffsetY = 4;
								ctxTop.drawImage(toothTopElement, xTop, yTop, toothWidth, toothHeight);

								ctxTop.restore();
								ctxBottom.restore();

								xBottom -= toothWidth;
								xTop = xBottom;
							}
							_this.frameID = requestAnimationFrame(drawTeeth);
						}
					};
					this.frameID = requestAnimationFrame(drawTeeth);
				} else {

					ctxTop.fillStyle = ctxBottom.fillStyle = backgroundColour;
					const trackWidth = canvasWidth * zipPercent / 100;

					ctxBottom.clearRect(0, 0, canvasWidth, height);
					ctxBottom.fillRect(0, 0, trackWidth, height);
					ctxTop.clearRect(0, 0, canvasWidth, height);
					ctxTop.fillRect(0, 0, trackWidth, height);

					const trackHeightDiv2 = trackHeight / 2;
					let xBottom = canvasWidth;
					let xTop = xBottom;
					const zipPosition = canvasWidth * zipPercent / 100 - toothPairWidth;

					while (xBottom > canvasWidth * zipPercent / 100 - toothPairWidth) {
						ctxTop.shadowColor = '#00000000';
						ctxBottom.shadowColor = '#00000000';
						const yTop = (canvasHeight - trackHeightDiv2) * (Math.sin(Math.PI * 0.5 + (xTop - zipPosition) * Math.PI / (zipPosition - canvasWidth)) + 1) / 2;
						const yBottom = canvasHeight - toothHeight - (canvasHeight - trackHeightDiv2) * (Math.sin(Math.PI * 0.5 + (xTop - zipPosition) * Math.PI / (canvasWidth - zipPosition)) + 1) / 2;

						ctxTop.fillRect(xTop, 0, toothWidth, yTop + 5);
						ctxBottom.fillRect(xBottom, yBottom + toothHeight - 5, toothWidth, canvasHeight - yBottom - toothHeight + 5);

						ctxBottom.shadowColor = '#00000050';
						ctxBottom.shadowBlur = 4;
						ctxBottom.shadowOffsetY = 0;
						ctxBottom.drawImage(toothBottomElement, xBottom, yBottom, toothWidth, toothHeight);

						ctxTop.shadowColor = '#00000070';
						ctxTop.shadowBlur = 4;
						ctxTop.shadowOffsetY = 4;
						ctxTop.drawImage(toothTopElement, xTop, yTop, toothWidth, toothHeight);

						xBottom -= toothWidth;
						xTop = xBottom;
					}
				}
			}
		}
	};

	handleMouseDown = (e) => {
		// console.log("handleMouseDown");
		this.mouseDown = true;
	};

	handleMouseLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.mouseDown = false;
	};

	handleMouseMove = (e) => {
		// console.log("handleMouseMove");
		const { unzipped = false } = this.state;
		e.preventDefault();
		if (this.mouseDown && !unzipped) {
			const {
				slideWidth,
				width,
				zipPercent: lastZipPercent,
			} = this.state;
			const { x } = this.mouseRelativeTo(e, '.zipper');
			const zipPercent = Math.max(0, Math.min(100, 100 * x / (width - slideWidth)));
			let unzipped = false;
			if (zipPercent <= 1) {
				unzipped = true;
				this.mouseDown = false;
			}
			this.setState({
				movingLeft: (zipPercent < lastZipPercent),
				unzipped: unzipped,
				zipPercent: zipPercent,
			}, this.drawInCanvases);
			if ("vibrate" in navigator){
				if (Math.abs(lastZipPercent - zipPercent) > 5){
					window.navigator.vibrate(10);
				}
			}
		}
	};

	handleMouseUp = (e) => {
		// console.log("handleMouseUp");
		this.mouseDown = false;
	};

	handleResize = () => {
		// console.log("handleResize");
		const { offsetWidth: w } = this.zipperRef.current;
		this.setState({
			// hidden: false,
			width: w,
		}, this.drawInCanvases);
	};

	mouseRelativeTo = (e, selector) => {
		// console.log("========== mouseRelativeTo selector", selector);
		let { pageX: x, pageY: y, target: node } = e;
		let id;
		let className;
		let datumFound = false;
		let dX = 0;
		let dY = 0;
		if (selector.includes('.')) className = selector.replace('.', '');
		if (selector.includes('#')) id = selector.replace('#', '');
		// Find the datum node
		while (!datumFound) {
			if ((className && node.classList.contains(className)) || (id && node.getAttribute('id') === id)){
				datumFound = true;
				({ x: dX, y: dY } = node.getBoundingClientRect());
				x -= dX;
				y -= dY;
			} else {
				// console.log("Step up");
				node = node.parentNode;
				if (node.getAttribute('id') === 'root') {
					console.log("mouseRelativeTo failed to find target", selector); // eslint-disable-line no-console
					return;
				}
			}
		}
		return { x, y };
	};

	recordOriginalHeight = () => {
		const { originalHeight } = this.state;
		if (!originalHeight) {
			const {
				offsetHeight: h,
			} = this.zipperRef.current;
			// console.log("recordOriginalHeight", h);
			this.setState({
				originalHeight: h,
			});
		}
	};

	render() {
		const {
			children,
			className,
			id,
		} = this.props;

		const {
			backgroundColour = 'white',
			fadeout = false,
			hidden = true,
			movingLeft = true,
			originalHeight,
			removed = false,
			trackHeight,
			unzipped = false,
			width,
			zipPercent = 100,
		} = this.state;

		let height = 0;
		// console.log("zipPercent", zipPercent);
		if (originalHeight) {
			height = Math.max(trackHeight, trackHeight + (originalHeight - trackHeight) * (100 - zipPercent) / 100);
			let heightString = `${height}px`;
			if (unzipped) heightString = 'auto';
			return (
				<div
					className={`
						zipper
						${className ? className : ''}
						${fadeout ? 'fadeout' : ''}
						${removed ? 'removed' : ''}
					 	${hidden ? 'hidden' : ''}
					`}
					id={`${id}`}
					onMouseMove={this.handleMouseMove}
					onMouseUp={this.handleMouseUp}
					onTouchMove={this.handleMouseMove}
					onTouchEnd={this.handleMouseUp}
					ref={this.zipperRef}
					style={{
						height: `${heightString}`,
					}}
				>
					<ReactResizeDetector
						handleHeight
						handleWidth
						onResize={this.handleResize}
						targetDomEl={this.zipperRef.current}
					></ReactResizeDetector>
					{!removed ?
						<>
							<canvas
								className={`zipper-canvas zipper-canvas-top`}
								ref={this.canvasTopRef}
								height={height / 2}
								width={width}
							/>
							<canvas
								className={`zipper-canvas zipper-canvas-bottom`}
								ref={this.canvasBottomRef}
								height={height / 2}
								width={width}
							/>
							<div
								className={`zipper-track`}
								ref={this.trackRef}
								style={{
									backgroundColor: `${backgroundColour}`,
									width: `${zipPercent}%`,
								}}
							>
								<div
									className={`zipper-slider`}
									onMouseDown={this.handleMouseDown}
									onMouseMove={this.handleMouseMove}
									onMouseUp={this.handleMouseUp}
									onTouchEnd={this.handleMouseUp}
									onTouchMove={this.handleMouseMove}
									onTouchStart={this.handleMouseDown}
								>
									<div className={`zipper-tab ${!movingLeft ? 'right' : ''}`} />
								</div>
							</div>
						</>
						:
						null
					}
					<div className={`zipper-content`}>
						{children}
					</div>
				</div>
			);
		} else {
			return (
				<div
					className={`zipper ${className ? className : ''}`}
					id={`${id}`}
					onMouseMove={this.handleMouseMove}
					onMouseUp={this.handleMouseUp}
					onTouchMove={this.handleMouseMove}
					onTouchEnd={this.handleMouseUp}
					ref={this.zipperRef}
				>
					<div className={`zipper-content`}>
						{children}
					</div>
				</div>
			);
		}
	}
}